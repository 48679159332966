import React from "react"
import { Link } from "gatsby"

const ThreeDots = props => {
  const linkSlug = props.link ? props.link : "/menu/"
  return (
    <>
      <div className=" pr-8 lg:pr-24 block font-normal text-right z-40 pt-6">
        <Link style={{ fontSize: "40px" }} to={linkSlug}>
          ...
        </Link>
      </div>
    </>
  )
}

export default ThreeDots
