import React from "react"
import ThreeDots from "../components/threeDots"
import { graphql, Link } from "gatsby"

export const query = graphql`
  {
    allSanityPage {
      nodes {
        title
        slug {
          current
        }
        pageType {
          typeName
        }
      }
    }
  }
`

const Menu = ({ data }) => {
  // const pages = data.allSanityPage.nodes
  // const whitePages = pages.filter(page => page.pageType.typeName === "White")

  const BoldText = props => (
    <li className="px-24 py-2 block font-medium text-right">
      {props.children.toUpperCase()}
    </li>
  )

  const LightText = props => (
    <li className="px-24 py-2 block font-light text-right">{props.children}</li>
  )

  return (
    <>
      <div className="w-screen h-screen" style={{ backgroundColor: "#F5F5F5" }}>
        <div
          className=" h-screen fixed z-10 top-0 right-0 bg-white  overflow-x-hidden"
          style={{ width: "505px", fontSize: "22px" }}
        >
          <ThreeDots link="/" />
          <div className="pt-16" />

          <p></p>
          <BoldText>Slow Glass Publication</BoldText>
          <Link to="/about/">
            <LightText>About</LightText>
          </Link>
          <Link to="/some-triangular-thoughts/">
            <LightText>Edition 1, Some Triangular Thoughts</LightText>
          </Link>
          <br />
          <BoldText>Curatorial Practice</BoldText>
          <Link to="/slow-glass-as-curatorial-practice/">
            <LightText>About</LightText>
          </Link>
          <br />
          <a href="mailto:slowglasspublication@gmail.com">
            <BoldText>Contact</BoldText>
          </a>
        </div>
      </div>
    </>
  )
}

export default Menu
